import React from 'react';
import PropTypes from 'prop-types';
import Base from 'Layouts/Base';
import Hero from 'Components/Hero';
import Entrances from 'Components/Entrances';
import Modules from 'Components/Modules';
import _ from 'lodash';
import s from './FrontPage.module.scss';

const FrontPage = ({hero, entrances, modules}) => (
    <div className={s['FrontPage']}>
        <Hero
            {...hero}
            hasEntrances={!_.isEmpty(entrances)}
            isLarge={true}
        />

        {!_.isEmpty(entrances) &&
            <Entrances {...entrances} isSmall={true} isOverlap={true} />
        }

        {!_.isEmpty(modules) &&
            <Modules {...modules} />
        }
    </div>
);

FrontPage.propTypes = {
    hero: PropTypes.object,
    entrances: PropTypes.object,
    modules: PropTypes.object,
};

FrontPage.defaultProps = {
    hero: null,
    entrances: null,
    modules: null,
};

export default Base(FrontPage);
